<template>
  <div class="bg-white rounded">
    <div class="px-4 mt-2">
      <TitlePlus :title="pageTitle" :hide-plus="true" class="mb-10" />
    </div>

    <div class="flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row">
      <SummaryCard
        title="Total Invoices"
        :value="indexMetaData.count.total"
        variant="green"
        class="mr-3"
      />
    </div>

    <FSTable
      :fst-id="fstId"
      :headers="getTableHeaders"
      :endpoint="getEndpoint"
      :qso="{ append: '', prepend: '&' }"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <!-- ./v-if[invoice] -->
          <template v-if="tableHeadersPreset === 'invoice'">
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.issue_date" date />

            <FSTableRowItem
              :text="item.due_date"
              class-name="text-oOrange"
              date
            />

            <FSTableRowItem>
              <XStatus
                :text="item.status"
                :profile="`payment`"
                :variant="item.status === 'PAID' ? 'green' : 'orange'"
              />
            </FSTableRowItem>

            <FSTableRowItem :text="item.currency.name" />

            <FSTableRowItem :text="item.currency.symbol + item.amount" />

            <FSTableRowItem>
              <AppButton height="30px" width="30px" variant="secondary">
                <i class="fa fa-download" />
              </AppButton>
            </FSTableRowItem>

            <FSTableRowItem>
              <AppButton
                height="27px"
                width="60px"
                rounded="none"
                variant="blue"
                text="Pay"
              />
            </FSTableRowItem>
          </template>
          <!-- ./v-if[invoice] -->

          <!-- v-if[subscription] -->
          <template v-else-if="tableHeadersPreset === 'transaction'">
            <FSTableRowItem :text="item.transaction" :truncate="-5" />

            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.payment_date" date />

            <FSTableRowItem>
              <i
                class="text-lg"
                :class="getCreditCardIcon(item.payment_method.brand)"
              />
              {{ '**** ' + $truncate(item.payment_method.number, -4) }}
            </FSTableRowItem>

            <FSTableRowItem
              :text="item.currency.symbol + item.amount"
              :class-name="`text-oGreen`"
            />

            <FSTableRowItem>
              <AppButton height="30px" width="30px" variant="secondary">
                <i class="fa fa-download" />
              </AppButton>
            </FSTableRowItem>

            <FSTableRowItem :text="item.checkout_attempts" />
          </template>
          <!-- ./v-if[subscription] -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { getCreditCardIcon } from '@/utils'

import TitlePlus from '@/components/ui/TitlePlus'
import SummaryCard from '@/components/cards/SummaryCard'
import XStatus from '@/components/badge/XStatus.vue'

const tableHeadersPreset = {
  invoice: [
    { text: 'ID', width: '10%', sort: 'id' },
    { text: 'Issue Date', width: '30%', sort: 'rider' },
    { text: 'Due Date', width: '15%', sort: 'date' },
    { text: 'Status', width: '15%', sort: 'date' },
    { text: 'Currency', width: '20%', sort: null },
    { text: 'Amount', width: '20%', sort: null },
    { text: 'Invoice', width: '20%', sort: null },
    { text: 'Actions', width: '10%', sort: null },
  ],
  transaction: [
    { text: 'Trx. ID', width: '10%', sort: 'id' },
    { text: 'Invoice ID', width: '10%', sort: 'rider' },
    { text: 'Trx. Date', width: '20%', sort: 'date' },
    { text: 'Payment Method', width: '15%', sort: 'date' },
    { text: 'Paid Amount', width: '15%', sort: null },
    { text: 'Invoice', width: '10%', sort: null },
    { text: 'Checkout Attempts', width: '20%', sort: null },
  ],
}

export default {
  name: 'SubscriptionChargesIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Subscription Charges',
    },

    fstId: {
      type: String,
      default: 'subscriptionCharges',
    },

    // it'll get priority over the tableHeadersPreset prop
    tableHeaders: {
      type: [Array, Boolean],
      validator: (val) => {
        if (typeof val === 'boolean') {
          return true
        }

        const hasItems = val.length > 0
        const hasProps = val.every((item) => {
          return (
            item?.text?.length > 0 &&
            item?.width?.length > 0 &&
            (typeof item.sort === 'string' || item.sort === null)
          )
        })
        return hasItems && hasProps
      },
      default: false,
    },

    tableHeadersPreset: {
      type: String,
      validator: (val) => ['invoice', 'transaction'].includes(val),
      default: 'invoice',
    },

    paidOnly: {
      type: Boolean,
      default: false,
    },

    unpaidOnly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FSTable,
    FSTableRow,
    FSTableRowItem,

    TitlePlus,
    SummaryCard,
    XStatus,
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
        summary: {
          total_unpaid_amount: 0,
          total: 1,
          paid: 1,
          unpaid: 0,
          past_due: 0,
        },
      },
      // default currency symbol for subscription charges
      currencySymbolDef: '$',

      addEdit: {
        model: '',
      },
    }
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.organization.subscriptionInvoice.index({
        paidOnly: this.paidOnly,
        unpaidOnly: this.unpaidOnly,
      })
    },

    getTableHeaders() {
      if (Array.isArray(this.tableHeaders)) {
        return this.tableHeaders
      }
      return tableHeadersPreset[this.tableHeadersPreset]
    },
  },

  methods: {
    getCreditCardIcon,
  },
}
</script>

<style lang="scss" scoped></style>
