<template>
  <div>
    <div class="card-container" :class="[getSizeClass, getVariantClass]">
      <div class="card-title">
        {{ title }}
      </div>
      <div class="card-body">
        <div class="left">{{ symbol ? `${symbol} ` : '' }}{{ value }}</div>
        <div class="right">
          <slot name="icon">
            <i class="icon" :class="icon"></i>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryCard',
  props: {
    title: { required: false, type: String },
    symbol: { required: false, type: String },
    value: { required: false, type: [String, Number] },
    icon: { required: false, type: String },
    size: { type: String, default: 'normal' },
    variant: { type: String, default: 'blue' },
  },
  computed: {
    getSizeClass: function() {
      const validSizes = {
        small: 'card-size-small',
        normal: 'card-size-normal',
        large: 'card-size-large',
      }
      return Object.keys(validSizes).includes(this.size)
        ? validSizes[this.size]
        : validSizes['normal']
    },
    getVariantClass: function() {
      const validVariants = [
        'deep-orange',
        'orange',
        'green',
        'red',
        'deep-blue',
        'blue',
        'light-blue',
        'yellow',
        'purple',
        'gray',
      ]
      let cssClass = 'variant-blue'

      if (!validVariants.includes(this.variant)) {
        return cssClass
      }
      cssClass = `variant-${this.variant}`
      return cssClass
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  display: block;
  // width: 190px;
  // height: 70px;
  padding: 4px 13px;
  border-radius: 5px;
  color: #fff;
}

.card-container.card-size-small {
  min-width: 160px;
  height: 70px;
}
.card-container.card-size-normal {
  min-width: 190px;
  height: 70px;
}
.card-container.card-size-large {
  min-width: 232px;
  height: 70px;
}

.card-container.variant-deep-orange {
  background-color: #f67606;
}
.card-container.variant-orange {
  background-color: #ffb100;
}
.card-container.variant-gray {
  @apply bg-gray-100;
   color: #030303;

}
.card-container.variant-yellow {
  background-color: #ffed00;
  color: #030303;
}
.card-container.variant-deep-blue {
  background-color: #0053ff;
}
.card-container.variant-blue {
  background-color: #0b77fe;
}
.card-container.variant-light-blue {
  background-color: #4aaafd;
}
.card-container.variant-green {
  background-color: #23c977;
  // background-color: #09aa35;
}
.card-container.variant-red {
  background-color: #d90a20;
}
.card-container.variant-purple {
  background-color: #7d3cf8;
}
.card-title {
  padding-top: 6px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.card-body {
  display: flex;
  justify-content: space-between;
}
.card-body .left {
  font-size: 22px;
  font-weight: 500;
}
.card-body .right {
  padding-right: 10px;
}
.card-body .right .icon {
  font-size: 28px;
}
</style>
